import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import './Navbar.css';

const Navbar = () => {
  const { keycloak } = useKeycloak();
  return (
    <nav className="navbar">
      <h1><Link to="/">FilmRecaps Admin Page</Link></h1>

      {keycloak.authenticated && (
        <div className="new-button">
          <Link to="/create">New Import</Link>
        </div>
      )}

      <div className="login-buttons">
        {!keycloak.authenticated && (
          <button
            type="button"
            className="login"
            onClick={() => keycloak.login()}
          >
            Login
          </button>
        )}
        {!!keycloak.authenticated && (
          <button
            type="button"
            className="logout"
            onClick={() => keycloak.logout()}
          >
            Logout ({keycloak.tokenParsed.preferred_username})
          </button>
        )}
      </div>
    </nav>
  );
}

export default Navbar;