import './ChannelName.css';

const ChannelName = (props) => {
    const ytChannelName = props.ytChannelName;

    return (
        <div className="channelname">Channel: {ytChannelName}</div> 
    );
}
 
export default ChannelName;