import Navbar from './Navbar';
import Home from './Home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "../Keycloak";
import Create from './Create';
import NotFound from '../NotFound';
import PrivateRoute from "../helpers/PrivateRoute";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


function App() {
  return (
    <Router>
      <div className="App">
        <ReactKeycloakProvider authClient={keycloak}>

          <Navbar />
          <div className="content">
            <Switch>
              <PrivateRoute>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/create">
                  <Create />
                </Route>
              </PrivateRoute>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </ReactKeycloakProvider>
      </div>
    </Router>
  );
}

export default App;