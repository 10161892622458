import './Stats.css';

const Stats = ({ movies, videos, channels }) => {

    return (
        <div className="stats">
            <p>Movies: {movies.length}</p>
            <p>Videos: {videos.length}</p>
            <p>Channels: {channels.length}</p>
        </div>
    );
}
 
export default Stats;