import Alert from 'react-bootstrap/Alert';
import Fade from 'react-bootstrap/Fade';
import './Alerts.css';

const Alerts = ({ successful, unsuccessful }) => {
  if (successful) {
    return (
      <Fade in={successful}>
        <div id="animation">
          <Alert variant="success">
            <Alert.Heading>Succesfully added!</Alert.Heading>
          </Alert>
        </div>
      </Fade>
    );
  }
  else if (unsuccessful) {
    return (
      <Fade in={unsuccessful}>
        <div id="animation">
          <Alert variant="danger">
            <Alert.Heading>Error adding data!</Alert.Heading>
          </Alert>
        </div>
      </Fade>
    );
  }
}

export default Alerts;
