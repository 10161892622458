import './MovieYear.css';

const MovieYear = (props) => {
    const movieyear = props.movieyear;

    return (
        <div className="movieyear">Year: {movieyear}</div>
    );
}
 
export default MovieYear;