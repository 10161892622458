import MovieYear from "./MovieYear";
import ChannelName from "./ChannelName";
import './Preview.css';

const Preview = (props) => {
    const movie = props.poster;
    const video = props.thumbnail;

    // child component props
    const movieyear = props.movieyear;
    const ytChannelName = props.ytChannelName;

    return (
        <div className="panel row">
            <div className="movie col-6">
                <h2>Movie Poster</h2>
                <img src={movie} className="mvposter" alt=""/>
                <MovieYear movieyear={movieyear}/>
            </div>

            <div className="video col-6">
                <h2>YouTube Thumbnail</h2>
                <img src={video} className="ytthumbnail" alt=""/>
                <ChannelName ytChannelName={ytChannelName}/>
            </div>

        </div>
    );
}
 
export default Preview;