import './Sidebar.css';

const Sidebar = () => {
    return (
        <div className="sidenav">
            <a href="#frontend" target="_blank" rel="noopener noreferrer">Live Site</a>
            <a href="https://api.movierecapsite.com" target="_blank" rel="noopener noreferrer">API</a>
            <a href="https://console.cloud.google.com/sql/instances/test-database/overview?authuser=1&project=movierecapsite&cloudshell=true&supportedpurview=project" target="_blank" rel="noopener noreferrer">Cloud DB</a>
            <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">YouTube</a>
        </div>
    );
}
 
export default Sidebar;