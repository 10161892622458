import { useState } from "react";
import { parse, toSeconds } from  "iso8601-duration";
import Preview from "./Preview";
import Alerts from "./Alerts"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faPaste, faMagnifyingGlass, faBan, faSpinner } from '@fortawesome/free-solid-svg-icons'
import './Create.css';


// external apis
const OMDB_BASE_URL = 'http://www.omdbapi.com/?'
const YOUTUBE_API_VIDEO_URL = 'https://www.googleapis.com/youtube/v3/videos?part='
const YOUTUBE_API_CHANNEL_URL = 'https://www.googleapis.com/youtube/v3/channels?part='

// internal apis
const IMPORT_API_URL = 'https://api.movierecapsite.com/import'

const movieDefault = 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/A12202_066_200607_Geirangerfjord_Skagefl_on_opposit_side.sized.jpeg/300px-A12202_066_200607_Geirangerfjord_Skagefl_on_opposit_side.sized.jpeg'
const videoDefault = 'https://cdn.mos.cms.futurecdn.net/ddznAc8UxXSrEVKtQEYUtW-320-80.jpg'

const Create = () => {
  // Form states
  const [title, setTitle] = useState('');
  const [year, setYear] = useState('');
  const [url, setUrl] = useState('');
  
  // Preview states
  const [poster, setPoster] = useState(movieDefault);
  const [thumbnail, setThumbnail] = useState(videoDefault);
  const [movieyear, setMovieYear] = useState('')
  const [ytChannelName, setytChannelName] = useState('')

  // Loading states
  const [isPending, setIsPending] = useState(false)
  const [isPendingFind, setIsPendingFind] = useState(false)

  // Alert states
  const[successful, setSuccessful] = useState(false)
  const[unsuccessful, setUnsuccessful] = useState(false)

  // API parameter strings
  const movieUrl = OMDB_BASE_URL + `t=${title}` + `&y=${year}` + `&apikey=${process.env.REACT_APP_OMDB_API_KEY}`
  const videoUrl = YOUTUBE_API_VIDEO_URL + 'snippet,contentDetails,statistics' + '&id=' + url.slice(32, 43) + `&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
  
  const fieldsEmpty = (title === "") && (url === "")

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPending(true)
    importScript(movieUrl, videoUrl);
  }

  const handleFind = (e) => {
    e.preventDefault();
    setIsPendingFind(true)
    getPreviewData(movieUrl, videoUrl);
  }

  const handleClear = (e) => {
    e.preventDefault();
    resetStates()
  }

  const resetStates = () => {
    setTitle("")
    setYear("")
    setUrl("")
    setPoster(movieDefault)
    setThumbnail(videoDefault)
    setMovieYear("")
    setytChannelName("")
  }
  
  async function importScript(movieUrl, videoUrl) {
    let movieTitle, movieYear, imdbID, posterUrl, genres, movieRating, movieCountry, movieLanguage;
    let videoTitle, videoDescription, videoThumbnailUrl, videoDuration, channelId, channelName, videoViews, videoDurationSeconds;
    let channelSubs, channelThumbnail;

    async function getMovieInfo() {
      try {
        const response = await fetch(movieUrl, {
        })
        const movies = await response.json()
        parseMovieInfo(movies)
        return response
      } catch (error) {
        console.log(error)
      }
    }
    
    async function getVideoInfo() {
      try {
        const response = await fetch(videoUrl, {
        })
        const videos = await response.json()
        parseVideoInfo(videos)
        return response
      } catch (error) {
        console.log(error)
      }
    }

    async function getChannelInfo() {
      const channelUrl = YOUTUBE_API_CHANNEL_URL + 'snippet,statistics' + '&id=' + channelId + `&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
  
      try {
        const response = await fetch(channelUrl, {
        })
        const channel = await response.json()
        parseChannelInfo(channel)
        return response
      } catch (error) {
        console.log(error)
      }
    }
    
    function parseMovieInfo(movies) {
      movieTitle = movies.Title
      movieYear = parseInt(movies.Year)
      imdbID = movies.imdbID
      posterUrl = movies.Poster
      genres = movies.Genre
      movieRating = movies.Rated
      movieCountry = movies.Country
      movieLanguage = movies.Language.split(",")[0]
      setIsPendingFind(false)
    }

    function parseVideoInfo(videos) {
      const baseYoutubeJson = videos.items[0]
      const snippetSection = baseYoutubeJson.snippet
      const contentDetailsSection = baseYoutubeJson.contentDetails
      const statisticsSection = baseYoutubeJson.statistics
  
      videoTitle = snippetSection.title
      videoDescription = snippetSection.description
      videoThumbnailUrl = snippetSection.thumbnails.default.url
      videoDuration = contentDetailsSection.duration
      channelId = snippetSection.channelId
      channelName = snippetSection.channelTitle
      videoViews = parseInt(statisticsSection.viewCount)
  
      videoDurationSeconds = toSeconds(parse(videoDuration))
      setIsPendingFind(false)
    }

    function parseChannelInfo(channel) {
      const baseYoutubeJson = channel.items[0]
      const snippetSection = baseYoutubeJson.snippet
      const statisticsSection = baseYoutubeJson.statistics
  
      channelSubs = parseInt(statisticsSection.subscriberCount)
      channelThumbnail = snippetSection.thumbnails.default.url.slice(0, 72)
    }

    async function formatPayload() {
      const importPayload = {
        movie: {
          id: imdbID,
          title: movieTitle,
          year: movieYear,
          posterUrl: posterUrl,
          genres: genres,
          rating: movieRating,
          country: movieCountry,
          language: movieLanguage
        },
        video: {
          id: url.slice(32, 43),
          title: videoTitle,
          length: videoDurationSeconds,
          views: videoViews
        },
        channel: {
          id: channelId,
          name: channelName,
          subscribers: channelSubs,
          thumbnail: channelThumbnail
        }
      }
      return importPayload
    }
    
    async function postImportBody(importPayload) {
      if (!importPayload.movie.id || !importPayload.video.id) {
        setIsPending(false)
        resetStates()
        unsuccessfulAlert(1500)
        throw new Error("No empty payload fields allowed") //prevents empty db post
      }
      try {
        const response = await fetch(IMPORT_API_URL, {
          method: 'POST',
          body: JSON.stringify(importPayload),
          headers: { 'Content-Type': 'application/json' }
        })
  
        const postResponse = await response.json()
        return postResponse
      } catch (error) {
        console.log(error)
      }
    }

    const movieInfo = await getMovieInfo()
    const videoInfo = await getVideoInfo()
    const channnelInfo = await getChannelInfo()
    const getBody = await formatPayload(movieInfo, videoInfo, channnelInfo)
    const importSuccesful =  await postImportBody(getBody)

    if (importSuccesful) {
      setIsPending(false)
      resetStates()
      successfulAlert(1500)
      return importSuccesful
    }
  }
  
  async function getPreviewData(movieUrl, videoUrl) {
    let posterUrl;
    let videoThumbnailUrl;
    let mvyear;
    let ytchannel;

    async function getMoviePoster() {
      try {
        const response = await fetch(movieUrl, {
        })
        const movies = await response.json()
        parseMoviePoster(movies)
        return response
      } catch (error) {
        console.log(error)
      }
    }
    
    async function getVideoThumbnail() {
      try {
        const response = await fetch(videoUrl, {
        })
        const videos = await response.json()
        parseVideoThumbnail(videos)
        return response
      } catch (error) {
        console.log(error)
      }
    }
    
    function parseMoviePoster(movies) {
      posterUrl = movies.Poster
      mvyear = movies.Year
      setPoster(posterUrl)
      setMovieYear(mvyear)
      setIsPendingFind(false)
    }

    function parseVideoThumbnail(videos) {
      const baseYoutubeJson = videos.items[0]
      const snippetSection = baseYoutubeJson.snippet
  
      videoThumbnailUrl = snippetSection.thumbnails.medium.url
      ytchannel = snippetSection.channelTitle
  
      setThumbnail(videoThumbnailUrl)
      setytChannelName(ytchannel)
      setIsPendingFind(false)
    }

    // TODO add error message for no movie/video found here
    const movieInfo = await getMoviePoster()
    const videoInfo = await getVideoThumbnail()
  }

  function paste(e) {
    navigator.clipboard.readText().then((text) => setUrl(text));
    e.preventDefault();
  }

  function successfulAlert(time) {
    setSuccessful(true);
    setTimeout(() => setSuccessful(false), time);
 }

 function unsuccessfulAlert(time) {
    setUnsuccessful(true);
    setTimeout(() => setUnsuccessful(false), time);
}

  return (
    <div className="create row">
      <Alerts successful={successful} unsuccessful={unsuccessful}/>
      <div className="entry col-6">
        <h2>Add a New Entry</h2>
        <form onSubmit={handleSubmit}>
          <label>Movie title:</label>
          <input 
            type="text" 
            required 
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <label>Movie Year:</label>
          <input
            value={year}
            onChange={(e) => setYear(e.target.value)}
          ></input>
          <label>Youtube URL:</label>
          <input
            type="text" 
            required
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          ></input>
          <div className="form-buttons">
            {!isPending && <button className="import"><FontAwesomeIcon className="fa-icon" icon={faArrowRight} size="lg" /></button>}
            {isPending && <button className="import" disabled><FontAwesomeIcon className="fa-spin" icon={faSpinner} size="lg" /></button>}
            {!isPendingFind && <button className="find" onClick={handleFind} disabled={fieldsEmpty}><FontAwesomeIcon icon={faMagnifyingGlass} size="lg" /></button>}
            {isPendingFind && <button className="find" disabled><FontAwesomeIcon className="fa-spin" icon={faSpinner} size="lg" /></button>}
            <button className="paste" onClick={paste}><FontAwesomeIcon icon={faPaste} size="lg"/></button>
            <button className="clear" onClick={handleClear}><FontAwesomeIcon icon={faBan} size="lg"/></button>
          </div>
        </form>
      </div>
      <div className="panel col-6">
        <Preview poster={poster} thumbnail={thumbnail} movieyear={movieyear} ytChannelName={ytChannelName}/>
      </div>
    </div>
  );
}
 
export default Create;