import EntryList from "./EntryList";
import useFetch from "./useFetch";
import Sidebar from "./Sidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Stats from "./Stats";
import './Home.css';

const Home = () => {
  const { error, isPending, data: movies } = useFetch('https://api.movierecapsite.com/movies')
  const { data: videos } = useFetch('https://api.movierecapsite.com/videos')
  const { data: channels } = useFetch('https://api.movierecapsite.com/channels')
  
  return (
    <div className="home">
      { error && <div>{ error }</div> }
      <h1>Latest additions: </h1>
      { isPending && <div><FontAwesomeIcon className="fa-spin" icon={faSpinner} size="lg" /></div> }
      { movies && <EntryList movies={movies} /> }
      <div className="home-sidebar">
        <Sidebar />
        <hr></hr>
        { isPending && <div><FontAwesomeIcon className="fa-spin" icon={faSpinner} size="lg" /></div> }
        { movies && videos && channels && <Stats movies={movies} videos={videos} channels={channels}/> }
      </div>

    </div>
  );
}
 
export default Home;