import './EntryList.css';

const EntryList = ({ movies }) => {
  let listings = [];
  for (let i = 4; i > 0; i--) { // get last i added movies
    listings.push(
      <div className="video-listing" key={i}>
        <h2>{movies.slice(-i)[0].title}</h2>
        <p>Year: {movies.slice(-i)[0].year}</p>
      </div>
    );
  }

  return (
    listings.reverse() // most recently added on top
  );
}

export default EntryList;